// async requests
export const FetchShivaListRequest = '{RS}shiva/FETCH_SHIVA_LIST_REQUEST'
export const FetchShivaListSuccess = '{RS}shiva/FETCH_SHIVA_LIST_SUCCESS'
export const FetchShivaListError = '{RS}shiva/FETCH_SHIVA_LIST_ERROR'

export const FetchShivaRequest = '{RS}shiva/FETCH_SHIVA_REQUEST'
export const FetchShivaSuccess = '{RS}shiva/FETCH_SHIVA_SUCCESS'
export const FetchShivaError = '{RS}shiva/FETCH_SHIVA_ERROR'

export const CreateShivaRequest = '{RS}shiva/CREATE_SHIVA_REQUEST'
export const CreateShivaSuccess = '{RS}shiva/CREATE_SHIVA_SUCCESS'
export const CreateShivaError = '{RS}shiva/CREATE_SHIVA_ERROR'

export const UpdateShivaRequest = '{RS}shiva/UPDATE_SHIVA_REQUEST'
export const UpdateShivaSuccess = '{RS}shiva/UPDATE_SHIVA_SUCCESS'
export const UpdateShivaError = '{RS}shiva/UPDATE_SHIVA_ERROR'

export const DeleteShivaRequest = '{RS}shiva/DELETE_SHIVA_REQUEST'
export const DeleteShivaSuccess = '{RS}shiva/DELETE_SHIVA_SUCCESS'
export const DeleteShivaError = '{RS}shiva/DELETE_SHIVA_ERROR'

// others

export const InitNewShiva = '{RS}shiva/Initialize new Shiva'
export const UpdateNewShiva = '{RS}shiva/Update new Shiva'
export const DeleteNewShiva = '{RS}shiva/Delete new Shiva'

export const AddVisit = '{RS}shiva/Add Shiva Visit'
export const UpdateVisit = '{RS}/shiva/Update Shiva Visit'
export const DeleteVisit = '{RS}/shiva/Delete Shiva Visit'

export const AddVisitor = '{RS}/Shiva/Add visitor to visit'

export const SelectShiva = '{RS}shiva/Select Shiva'
export const ResetShiva = '{RS}shiva/Reset all shivas from store'

export const SelectVisit = '{RS}shiva/Select Visit'

export const ClearError = '{RS}shiva/Clear error'
