import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import * as Routes from '../../routes'
import BackArrowIcon from '../../assets/img/back-arrow.svg'

const BackArrow = styled.img`
  width: 18px;
  height: 15px;
  margin-right: 9px;
  object-fit: contain;
  display: inline-block;
`

const BackWrapper = styled.div`
  margin-bottom: 40px;
  cursor: pointer;
`

const Back = () => {
  const dispatch = useDispatch()
  return (
    <BackWrapper onClick={() => dispatch(push(Routes.MY_SHIVAS))}>
      <div>
        <BackArrow src={BackArrowIcon} />
        Back to My Shivas
      </div>
    </BackWrapper>
  )
}

export default Back
