import React, { useState } from 'react'
import { Shiva, Role } from '../../store/shiva/types'
import { Row, FlexColumn, FixedColumn } from '../../components/flexLayout'
import Subject from './Subject'
import About from './About'
import Schedule from './Schedule'
import Mourners from './Mourners'
import VideoLink from './VideoLink'
import MinyanTimes from './MinyanTimes'
import Meals from './Meals'
import Donations from './Donations'
import InviteVisitors from './InviteVisitors'
import { LayoutWrapper, Direction, Button } from './styles'

interface Props {
  shiva: Shiva
  role: Role
}

const ShivaTemplate = ({ shiva, role }: Props) => {
  const [showDialog, setShowDialog] = useState(false)
  return (
    <LayoutWrapper>
      <Row>
        <FlexColumn>
          <Subject shiva={shiva} darkMode={false} role={role} direction={Direction.row} />
          <Schedule shiva={shiva} darkMode={false} role={role} direction={Direction.column} />
          <About shiva={shiva} darkMode={shiva.about || shiva.images.length > 0 ? false : true} role={role} direction={Direction.column} />
        </FlexColumn>
        <aside style={{ marginLeft: '20px' }}>
          <FixedColumn width={300}>
            {role !== 'Visitor' ? <Button onClick={() => setShowDialog(true)}>Invite visitors</Button> : null}
            <VideoLink shiva={shiva} darkMode={shiva.videoLink ? false : true} role={role} direction={Direction.column} />
            <Mourners shiva={shiva} darkMode={false} role={role} direction={Direction.column} />
            <MinyanTimes shiva={shiva} darkMode={shiva.minyanTimes ? false : true} role={role} direction={Direction.column} />
            <Meals shiva={shiva} darkMode={shiva.mealSignups ? false : true} role={role} direction={Direction.column} />
            <Donations shiva={shiva} darkMode={shiva.donations ? false : true} role={role} direction={Direction.column} />
          </FixedColumn>
        </aside>
      </Row>
      {showDialog ? <InviteVisitors shiva={shiva} onClose={() => setShowDialog(false)} /> : null}
    </LayoutWrapper>
  )
}

export default ShivaTemplate
